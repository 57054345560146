import React, { useEffect, useState } from "react";
import "./App.css";
import mobileImage from "./images/mobileBackground.jpg";
import mobileImage2 from "./images/mobileBackground2.jpg";
import mobileImage3 from "./images/mobileBackground3.jpg";
import mobileImage4 from "./images/mobileBackground4.jpg";
import mobileImage5 from "./images/mobileBackground5.jpg";
import mobileImage6 from "./images/mobileBackground6.jpg";
import mobileImage7 from "./images/mobileBackground7.jpg";
import mobileImage8 from "./images/mobileBackground8.jpg";
import desktopImage from "./images/desktopBackground.jpg";
import desktopImage2 from "./images/desktopBackground2.jpg";
import desktopImage3 from "./images/desktopBackground3.jpg";
import desktopImage4 from "./images/desktopBackground4.jpg";
import desktopImage5 from "./images/desktopBackground5.jpg";
import desktopImage6 from "./images/desktopBackground6.jpg";
import desktopImage8 from "./images/desktopBackground8.jpg";
import desktopImage9 from "./images/desktopBackground9.jpg";
import desktopImage10 from "./images/desktopBackground10.jpg";
import desktopImage11 from "./images/desktopBackground11.jpg";
import desktopImage12 from "./images/desktopBackground12.jpg";
import desktopImage13 from "./images/desktopBackground13.jpg";
import desktopImage14 from "./images/desktopBackground14.jpg";
import desktopImage15 from "./images/desktopBackground15.jpg";
import desktopImage16 from "./images/desktopBackground16.jpg";
import desktopImage17 from "./images/desktopBackground17.jpg";
import desktopImage18 from "./images/desktopBackground18.jpg";
import desktopImage19 from "./images/desktopBackground19.jpg";
import desktopImage20 from "./images/desktopBackground20.jpg";
import Joke from "./Joke";

function App() {
  const mobileBackgrounds = [
    mobileImage,
    mobileImage2,
    mobileImage3,
    mobileImage4,
    mobileImage5,
    mobileImage6,
    mobileImage7,
    mobileImage8,
  ];
  const desktopBackgrounds = [
    desktopImage,
    desktopImage2,
    desktopImage3,
    desktopImage4,
    desktopImage5,
    desktopImage6,
    desktopImage8,
    desktopImage9,
    desktopImage10,
    desktopImage11,
    desktopImage12,
    desktopImage13,
    desktopImage14,
    desktopImage15,
    desktopImage16,
    desktopImage17,
    desktopImage18,
    desktopImage19,
    desktopImage20,
  ];
  const [mobileBackground, setMobileBackground] = useState("");
  const [desktopBackground, setDesktopBackground] = useState("");
  const [mainTitle, setMainTitle] = useState("Hello Candy");
  const [secondaryTitle, setSecondaryTitle] = useState("");
  useEffect(() => {
    const mobileFinder =
      mobileBackgrounds[Math.floor(Math.random() * mobileBackgrounds.length)];
    const desktopFinder =
      desktopBackgrounds[Math.floor(Math.random() * desktopBackgrounds.length)];
    const date = new Date();
    if (date.getMonth() === 4 && date.getDate() === 16) {
      setMainTitle("HAPPY BIRTHDAY CANDY!!");
    } else if (date.getHours() < 12) {
      setMainTitle("Good Morning Candy :D");
    } else if (date.getHours() < 17) {
      setMainTitle("Good Afternoon Candy");
    } else if (date.getHours() < 22) {
      setMainTitle("Good Evening Candy");
    } else {
      setMainTitle("Good Night Candy :)");
    }
    if (date.getMonth() === 4 && date.getDate() === 16) {
      setSecondaryTitle("I hope you have a great day today :)");
    }
    // else if (date.getHours() < 5) {
    //   setSecondaryTitle("Sleep and get your rest!!");
    // } else if (date.getDay() === 6) {
    //   setSecondaryTitle("Have a good weekend!");
    // } else if (date.getDay() === 1) {
    //   setSecondaryTitle("Hope your week goes well!");
    // } else if (date.getDay() === 3) {
    //   setSecondaryTitle("Almost to the weekend 😭 You're doing great!");
    // }
    setMobileBackground(mobileFinder);
    setDesktopBackground(desktopFinder);
  }, []);

  return (
    <div className="App h-[100vh] w-full overflow-y-hidden">
      <div
        className=" h-full flex flex-col text-white items-center lg:hidden"
        style={{
          backgroundImage: `url(${mobileBackground})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <div className=" text-black p-4 flex flex-col justify-center mt-[85px] rounded-2xl bg-white mx-[30px] opacity-80">
          <h1 className="text-2xl">{mainTitle}</h1>
          <span className="italic text-lg">{secondaryTitle}</span>
          <Joke />
        </div>
      </div>

      <div
        className=" h-full  lg:flex-col text-white items-center hidden lg:flex"
        style={{
          backgroundImage: `url(${desktopBackground})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <div className=" text-black p-4 flex flex-col justify-center mt-[85px] rounded-2xl bg-white mx-[30px] opacity-[77%] max-w-[800px] min-w-[650px]">
          <h1 className="text-2xl">{mainTitle}</h1>
          <span className="italic text-lg">{secondaryTitle}</span>
          <Joke />
        </div>
      </div>
    </div>
  );
}

export default App;
