import React, { useState, useEffect } from "react";
import axios from "axios";

function Joke() {
  const [joke, setJoke] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isExplainModalOpen, setIsExplainModalOpen] = useState(false);
  const [isTikTokModalOpen, setIsTikTokModalOpen] = useState(false);

  async function fetchJoke() {
    try {
      setIsLoading(true);
      const response = await axios.get("https://icanhazdadjoke.com/", {
        headers: { Accept: "application/json" },
      });
      setJoke(response.data.joke);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchJoke();
  }, []);

  return (
    <>
      <div className="flex-col pt-[0.75rem]">
        <span>{joke}</span>
        <div className="flex flex-col lg:flex-row justify-evenly pt-[1rem]">
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mb-[0.5rem] lg:mb-0"
            onClick={() => setIsExplainModalOpen(true)}
          >
            Is This Finished?
          </button>
          {/* <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mb-[0.5rem] lg:mb-0"
            onClick={() => setIsTikTokModalOpen(true)}
          >
            Wholesome TikToks
          </button> */}
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            onClick={fetchJoke}
          >
            Show Me a New Joke!
          </button>
        </div>
      </div>
      {isExplainModalOpen && (
        <div className="fixed w-full h-full bg-black bg-opacity-75 z-50 top-0 left-0 overflow-y-scroll">
          <div className="w-full flex justify-center">
            <div className="bg-white rounded-lg p-6 mt-[85px] max-w-[800px] lg:min-w-[650px]">
              <p className="text-black text-left">
                Yes, this is finished. I wish you the best and I hope you heal
                from the things you don't talk about.
              </p>
              <div className="mt-[1.25rem]">
                <p>Always rooting for your happiness,</p>
                <p>Eric</p>
              </div>
              <button
                onClick={() => setIsExplainModalOpen(false)}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-[0.75rem]"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
      {/* {isTikTokModalOpen && (
        <div className="fixed w-full h-full bg-black bg-opacity-75 z-50 top-0 left-0 overflow-y-scroll">
          <div className="w-full flex justify-center">
            <div className="bg-white rounded-lg p-6 mt-[85px] max-w-[800px] lg:min-w-[650px]">
              <div className="flex flex-col items-start">
                <p className="text-black text-left">
                  Here are all of the remaining wholesome TikToks that I never
                  got to send, either because I didn't have the time or just
                  because the situation never arose. These are in no way ranked,
                  I just figured it would be easier to keep track of which ones
                  have been watched if they're numbered. There's one duplicate
                  link somewhere but I have no idea where it is 💀. Sorry in
                  advance for the cheese LMFAO. Enjoy :)
                </p>
                <ol
                  type="1"
                  className="list-decimal list-inside mt-[1rem] text-left"
                >
                  <li>
                    <a
                      href="https://www.tiktok.com/t/ZTRpGTKRT/"
                      target="_blank"
                      className="underline"
                      rel="noreferrer"
                    >
                      https://www.tiktok.com/t/ZTRpGTKRT/
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.tiktok.com/t/ZTRpG7rrR/"
                      target="_blank"
                      className="underline"
                      rel="noreferrer"
                    >
                      https://www.tiktok.com/t/ZTRpG7rrR/
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.tiktok.com/t/ZTRpGvN6e/"
                      target="_blank"
                      className="underline"
                      rel="noreferrer"
                    >
                      https://www.tiktok.com/t/ZTRpGvN6e/
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.tiktok.com/t/ZTRpG75TM/"
                      target="_blank"
                      className="underline"
                      rel="noreferrer"
                    >
                      https://www.tiktok.com/t/ZTRpG75TM/
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.tiktok.com/t/ZTRpGpScu/"
                      target="_blank"
                      className="underline"
                      rel="noreferrer"
                    >
                      https://www.tiktok.com/t/ZTRpGpScu/
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.tiktok.com/t/ZTRptJXSr/"
                      target="_blank"
                      className="underline"
                      rel="noreferrer"
                    >
                      https://www.tiktok.com/t/ZTRptJXSr/
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.tiktok.com/t/ZTRpGG2Af/"
                      target="_blank"
                      className="underline"
                      rel="noreferrer"
                    >
                      https://www.tiktok.com/t/ZTRpGG2Af/
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.tiktok.com/t/ZTRpGppjx/"
                      target="_blank"
                      className="underline"
                      rel="noreferrer"
                    >
                      https://www.tiktok.com/t/ZTRpGppjx/
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.tiktok.com/t/ZTRpGVncR/"
                      target="_blank"
                      className="underline"
                      rel="noreferrer"
                    >
                      https://www.tiktok.com/t/ZTRpGVncR/
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.tiktok.com/t/ZTRpGVnL2/"
                      target="_blank"
                      className="underline"
                      rel="noreferrer"
                    >
                      https://www.tiktok.com/t/ZTRpGVnL2/
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.tiktok.com/t/ZTRpGcXT6/"
                      target="_blank"
                      className="underline"
                      rel="noreferrer"
                    >
                      https://www.tiktok.com/t/ZTRpGcXT6/
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.tiktok.com/t/ZTRpGqa3p/"
                      target="_blank"
                      className="underline"
                      rel="noreferrer"
                    >
                      https://www.tiktok.com/t/ZTRpGqa3p/
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.tiktok.com/t/ZTRpGoNyC/"
                      target="_blank"
                      className="underline"
                      rel="noreferrer"
                    >
                      https://www.tiktok.com/t/ZTRpGoNyC/
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.tiktok.com/t/ZTRpGn7Sn/"
                      target="_blank"
                      className="underline"
                      rel="noreferrer"
                    >
                      https://www.tiktok.com/t/ZTRpGn7Sn/
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.tiktok.com/t/ZTRpGoTKD/"
                      target="_blank"
                      className="underline"
                      rel="noreferrer"
                    >
                      https://www.tiktok.com/t/ZTRpGoTKD/
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.tiktok.com/t/ZTRpGV4dP/"
                      target="_blank"
                      className="underline"
                      rel="noreferrer"
                    >
                      https://www.tiktok.com/t/ZTRpGV4dP/
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.tiktok.com/t/ZTRpGTxCF/"
                      target="_blank"
                      className="underline"
                      rel="noreferrer"
                    >
                      https://www.tiktok.com/t/ZTRpGTxCF/
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.tiktok.com/t/ZTRpGKoNY/"
                      target="_blank"
                      className="underline"
                      rel="noreferrer"
                    >
                      https://www.tiktok.com/t/ZTRpGKoNY/
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.tiktok.com/t/ZTRpGgMoV/"
                      target="_blank"
                      className="underline"
                      rel="noreferrer"
                    >
                      https://www.tiktok.com/t/ZTRpGgMoV/
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.tiktok.com/t/ZTRpGnYQx/"
                      target="_blank"
                      className="underline"
                      rel="noreferrer"
                    >
                      https://www.tiktok.com/t/ZTRpGnYQx/
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.tiktok.com/t/ZTRptJB6h/"
                      target="_blank"
                      className="underline"
                      rel="noreferrer"
                    >
                      https://www.tiktok.com/t/ZTRptJB6h/
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.tiktok.com/t/ZTRpGKKgd/"
                      target="_blank"
                      className="underline"
                      rel="noreferrer"
                    >
                      https://www.tiktok.com/t/ZTRpGKKgd/
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.tiktok.com/t/ZTRpGKUpm/"
                      target="_blank"
                      className="underline"
                      rel="noreferrer"
                    >
                      https://www.tiktok.com/t/ZTRpGKUpm/
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.tiktok.com/t/ZTRpGtanY/"
                      target="_blank"
                      className="underline"
                      rel="noreferrer"
                    >
                      https://www.tiktok.com/t/ZTRpGtanY/
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.tiktok.com/t/ZTRpGprsv/"
                      target="_blank"
                      className="underline"
                      rel="noreferrer"
                    >
                      https://www.tiktok.com/t/ZTRpGprsv/
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.tiktok.com/t/ZTRpGsGGE/"
                      target="_blank"
                      className="underline"
                      rel="noreferrer"
                    >
                      https://www.tiktok.com/t/ZTRpGsGGE/
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.tiktok.com/t/ZTRpGpsTE/"
                      target="_blank"
                      className="underline"
                      rel="noreferrer"
                    >
                      https://www.tiktok.com/t/ZTRpGpsTE/
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.tiktok.com/t/ZTRpGEQSw/"
                      target="_blank"
                      className="underline"
                      rel="noreferrer"
                    >
                      https://www.tiktok.com/t/ZTRpGEQSw/
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.tiktok.com/t/ZTRpGpSPS/"
                      target="_blank"
                      className="underline"
                      rel="noreferrer"
                    >
                      https://www.tiktok.com/t/ZTRpGpSPS/
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.tiktok.com/t/ZTRpGsEVR/"
                      target="_blank"
                      className="underline"
                      rel="noreferrer"
                    >
                      https://www.tiktok.com/t/ZTRpGsEVR/
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.tiktok.com/t/ZTRptdrpF/"
                      target="_blank"
                      className="underline"
                      rel="noreferrer"
                    >
                      https://www.tiktok.com/t/ZTRptdrpF/
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.tiktok.com/t/ZTRpG3Yxt/"
                      target="_blank"
                      className="underline"
                      rel="noreferrer"
                    >
                      https://www.tiktok.com/t/ZTRpG3Yxt/
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.tiktok.com/t/ZTRpG4gSa/"
                      target="_blank"
                      className="underline"
                      rel="noreferrer"
                    >
                      https://www.tiktok.com/t/ZTRpG4gSa/
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.tiktok.com/t/ZTRpGTCV5/"
                      target="_blank"
                      className="underline"
                      rel="noreferrer"
                    >
                      https://www.tiktok.com/t/ZTRpGTCV5/
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.tiktok.com/t/ZTRpGGE4K/"
                      target="_blank"
                      className="underline"
                      rel="noreferrer"
                    >
                      https://www.tiktok.com/t/ZTRpGGE4K/
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.tiktok.com/t/ZTRpGWPgk/"
                      target="_blank"
                      className="underline"
                      rel="noreferrer"
                    >
                      https://www.tiktok.com/t/ZTRpGWPgk/
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.tiktok.com/t/ZTRpGXHAK/"
                      target="_blank"
                      className="underline"
                      rel="noreferrer"
                    >
                      https://www.tiktok.com/t/ZTRpGXHAK/
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.tiktok.com/t/ZTRpGcFCE/"
                      target="_blank"
                      className="underline"
                      rel="noreferrer"
                    >
                      https://www.tiktok.com/t/ZTRpGcFCE/
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.tiktok.com/t/ZTRptdkVe/"
                      target="_blank"
                      className="underline"
                      rel="noreferrer"
                    >
                      https://www.tiktok.com/t/ZTRptdkVe/
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.tiktok.com/t/ZTRpGXDf3/"
                      target="_blank"
                      className="underline"
                      rel="noreferrer"
                    >
                      https://www.tiktok.com/t/ZTRpGXDf3/
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.tiktok.com/t/ZTRpG7KbK/"
                      target="_blank"
                      className="underline"
                      rel="noreferrer"
                    >
                      https://www.tiktok.com/t/ZTRpG7KbK/
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.tiktok.com/t/ZTRpGoF1S/"
                      target="_blank"
                      className="underline"
                      rel="noreferrer"
                    >
                      https://www.tiktok.com/t/ZTRpGoF1S/
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.tiktok.com/t/ZTRpteN17/"
                      target="_blank"
                      className="underline"
                      rel="noreferrer"
                    >
                      https://www.tiktok.com/t/ZTRpteN17/
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.tiktok.com/t/ZTRptRY2P/"
                      target="_blank"
                      className="underline"
                      rel="noreferrer"
                    >
                      https://www.tiktok.com/t/ZTRptRY2P/
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.tiktok.com/t/ZTRpt1vMY/"
                      target="_blank"
                      className="underline"
                      rel="noreferrer"
                    >
                      https://www.tiktok.com/t/ZTRpt1vMY/
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.tiktok.com/t/ZTRpGXbqW/"
                      target="_blank"
                      className="underline"
                      rel="noreferrer"
                    >
                      https://www.tiktok.com/t/ZTRpGXbqW/
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.tiktok.com/t/ZTRpGESrM/"
                      target="_blank"
                      className="underline"
                      rel="noreferrer"
                    >
                      https://www.tiktok.com/t/ZTRpGESrM/
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.tiktok.com/t/ZTRpGvCKr/"
                      target="_blank"
                      className="underline"
                      rel="noreferrer"
                    >
                      https://www.tiktok.com/t/ZTRpGvCKr/
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.tiktok.com/t/ZTRpGW2tS/"
                      target="_blank"
                      className="underline"
                      rel="noreferrer"
                    >
                      https://www.tiktok.com/t/ZTRpGW2tS/
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.tiktok.com/t/ZTRpGpbsg/"
                      target="_blank"
                      className="underline"
                      rel="noreferrer"
                    >
                      https://www.tiktok.com/t/ZTRpGpbsg/
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.tiktok.com/t/ZTRpGcDgh/"
                      target="_blank"
                      className="underline"
                      rel="noreferrer"
                    >
                      https://www.tiktok.com/t/ZTRpGcDgh/
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.tiktok.com/t/ZTRpGbPg3/"
                      target="_blank"
                      className="underline"
                      rel="noreferrer"
                    >
                      https://www.tiktok.com/t/ZTRpGbPg3/
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.tiktok.com/t/ZTRpGGemx/"
                      target="_blank"
                      className="underline"
                      rel="noreferrer"
                    >
                      https://www.tiktok.com/t/ZTRpGGemx/
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.tiktok.com/t/ZTRpGv4e2/"
                      target="_blank"
                      className="underline"
                      rel="noreferrer"
                    >
                      https://www.tiktok.com/t/ZTRpGv4e2/
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.tiktok.com/t/ZTRpGTALY/"
                      target="_blank"
                      className="underline"
                      rel="noreferrer"
                    >
                      https://www.tiktok.com/t/ZTRpGTALY/
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.tiktok.com/t/ZTRpGqq3U/"
                      target="_blank"
                      className="underline"
                      rel="noreferrer"
                    >
                      https://www.tiktok.com/t/ZTRpGqq3U/
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.tiktok.com/t/ZTRpG4x2o/"
                      target="_blank"
                      className="underline"
                      rel="noreferrer"
                    >
                      https://www.tiktok.com/t/ZTRpG4x2o/
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.tiktok.com/t/ZTRpGwH1r/"
                      target="_blank"
                      className="underline"
                      rel="noreferrer"
                    >
                      https://www.tiktok.com/t/ZTRpGwH1r/
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.tiktok.com/t/ZTRpGsktD/"
                      target="_blank"
                      className="underline"
                      rel="noreferrer"
                    >
                      https://www.tiktok.com/t/ZTRpGsktD/
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/reel/Ck8CY7crlZw/?igshid=Zjc2ZTc4Nzk="
                      target="_blank"
                      className="underline"
                      rel="noreferrer"
                    >
                      https://www.instagram.com/reel/Ck8CY7crlZw/?igshid=Zjc2ZTc4Nzk=
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/reel/Cm8Hrq5LzTl/?igshid=Zjc2ZTc4Nzk="
                      target="_blank"
                      className="underline"
                      rel="noreferrer"
                    >
                      https://www.instagram.com/reel/Cm8Hrq5LzTl/?igshid=Zjc2ZTc4Nzk=
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/reel/Cm3S1NzBEcZ/?igshid=Zjc2ZTc4Nzk="
                      target="_blank"
                      className="underline"
                      rel="noreferrer"
                    >
                      https://www.instagram.com/reel/Cm3S1NzBEcZ/?igshid=Zjc2ZTc4Nzk=
                    </a>
                  </li>
                </ol>
              </div>
              <button
                onClick={() => setIsTikTokModalOpen(false)}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-[1.25rem]"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )} */}
    </>
  );
}

export default Joke;
